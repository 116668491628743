import { Auth } from "aws-amplify"
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "./helpers/withSetPropAction"

export enum UserAuthStages {
  Register,
  Login,
  EmailInvalidated,
  PhoneInvalidated,
  Auth,
}

export enum AmplifyErrorTypes {
  NotAuthorizedException = "NotAuthorizedException",
  UsernameExistsException = "UsernameExistsException",
  UserNotConfirmedException = "UserNotConfirmedException",
  ExpiredCodeException = "ExpiredCodeException",
  NetworkError = "NetworkError"
}
export const AuthenticationStoreModel = types
  .model("AuthenticationStore")
  .props({
    authStage: UserAuthStages.Login,
    authToken: types.maybe(types.string),
    email: "",
    email_verified: false,
    error: "",
    family_name: "",
    isLoading: false,
    name: "",
    password: "",
    phone_number: "",
    phone_number_verified: false,
    userId: "",
    verifyCode: "",
  })
  .views((store) => ({
    get isAuthenticated() {
      return store.authStage === UserAuthStages.Auth
    },
    get isRegistering() {
      return store.authStage === UserAuthStages.Register
    },
    get isLogging() {
      return store.authStage === UserAuthStages.Login
    },
    get isEmailInvalidated() {
      return store.authStage === UserAuthStages.EmailInvalidated
    },
    get isPhoneInvalidated() {
      return store.authStage === UserAuthStages.PhoneInvalidated
    },
    get validationErrors() {
      return {
        authEmail: (function () {
          if (store.email.length === 0) return "can't be blank"
          if (store.email.length < 6) return "must be at least 6 characters"
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(store.email))
            return "must be a valid email address"
          return ""
        })(),
        authPassword: (function () {
          if (store.password.length === 0) return "can't be blank"
          if (store.password.length < 6) return "must be at least 6 characters"
          return ""
        })(),
      }
    },
  }))
  .actions(withSetPropAction)
  .actions((store) => ({
    onReset: () => {
      store.setProp("authStage", UserAuthStages.Login)
      store.setProp("error", "")
    },
    onUpdateUserAttribs: (attribs) => {
      attribs = attribs
        .flatMap(({ Name, Value }) => ({ [Name === "sub" ? "username" : Name]: Value }))
        .reduce((cur, acc) => (acc = { ...acc, ...cur }), {})

      if (!(attribs.email_verified === "true")) store.authStage = UserAuthStages.EmailInvalidated
      store.setProp("email_verified", attribs.email_verified === "true")

      if (!(attribs.phone_number_verified === "true"))
        store.authStage = UserAuthStages.PhoneInvalidated
      store.setProp("phone_number_verified", attribs.phone_number_verified === "true")

      if (attribs.email_verified && store.phone_number_verified)
        store.setProp("authStage", UserAuthStages.Auth)
    },
    onCleanState: () => {
      // store.setProp("")
    },
    onCleanError: () => {
      store.setProp("error", "")
    },
  }))
  .actions((store) => ({
    setAuthEmail(value: string) {
      store.email = value.replace(/ /g, "")
    },
    setAuthPassword(value: string) {
      store.password = value.replace(/ /g, "")
    },
    setAuthName(value: string) {
      store.name = value.trim()
    },
    setAuthFamilyName(value: string) {
      store.family_name = value.trim()
    },
    setAuthPhone(value: string) {
      store.phone_number = value
    },
    setVerifyCode(value: "") {
      store.verifyCode = value
    },
    onRegister: () => {
      store.setProp("authStage", UserAuthStages.Register)
      store.setProp("error", "")
    },
    onLogin: () => {
      store.setProp("authStage", UserAuthStages.Login)
      store.setProp("error", "")
    },
    onSignIn: async () => {
      console.tron.log("onSignIn")
      store.setProp("isLoading", true)
      store.setProp("error", "")
      store.setProp("verifyCode", "")
      try {
        const authenticationData = {
          username: store.email,
          password: store.password,
        }
        const user = await Auth.signIn(authenticationData)
        console.tron.log("onSignIn Response", user)

        const session = await Auth.currentAuthenticatedUser()
        console.tron.log("onSignIn Session", session)

        const attribs = await Auth.userAttributes(user)
        console.tron.log("onSignIn attribs Response", user)
        store.onUpdateUserAttribs(attribs)
        store.setProp("authToken", user.signInUserSession.accessToken.jwtToken)
        console.tron.log("onSignIn Signed", attribs)
      } catch (error) {
        if (error.code) {
          console.tron.log("onSignIn Error:", error)
          switch (error.code) {
            case AmplifyErrorTypes.NotAuthorizedException:
              return store.setProp("error", "Usuario o clave incorrecto.")
            case AmplifyErrorTypes.UserNotConfirmedException:
              store.setProp("email_verified", false)
              store.setProp("authStage", UserAuthStages.EmailInvalidated)
              return store.setProp("error", "Debe confirmar su correo.")
            default:
              return store.setProp("error", error.message)
          }
        }
        store.setProp("error", error.message)
      } finally {
        store.setProp("isLoading", false)
      }
    },
    onSignUp: async () => {
      store.setProp("isLoading", true)
      store.setProp("error", "")
      store.setProp("verifyCode", "")
      try {
        console.tron.log("onSignUp")
        const response = await Auth.signUp({
          username: store.email,
          password: store.password,
          attributes: {
            email: store.email,
            family_name: store.family_name,
            name: store.name,
            phone_number: store.phone_number,
            picture: "",
            birthdate: "",
            locale: "",
            address: "",
            gender: "",
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true,
          },
        })
        console.tron.log("onSignUp Response", response)
        if(!response.userConfirmed) store.setProp("authStage", UserAuthStages.EmailInvalidated)
        store.setProp("userId", response.userSub)
        store.setProp("email_verified", response.userConfirmed)
      } catch (error) {
        console.tron.log("onSignUp Error", error)
        store.setProp("error", error.message)
      } finally {
        store.setProp("isLoading", false)
      }
    },
    onGetCurrentSession: async () => {
      store.setProp("isLoading", true)
      try {
        console.tron.log("getCurrentSession")
        const user = await Auth.currentAuthenticatedUser()
        const session = await Auth.userSession(user)
        store.onUpdateUserAttribs(user.attribs)
        store.setProp("authToken", user.signInUserSession.accessToken.jwtToken)
        store.setProp("authStage", UserAuthStages.Auth)
        console.tron.log("getCurrentSession Response", session)
      } catch (error) {        
        console.tron.log("getCurrentSession Error", error)
      } finally {
        store.setProp("isLoading", false)
      }
    },
    logout() {
      store.authToken = undefined
      store.email = ""
      store.password = ""
      store.authStage = UserAuthStages.Register
      store.email_verified = false
      store.error = ""
      store.password = ""
      store.phone_number_verified = false
      store.setProp("isLoading", false)
    },
  }))
  .actions((store) => ({
    onConfirmAccount: async (verifyCode) => {
      store.setProp("isLoading", true)
      try {
        console.tron.log("confirmSignUp")
        const response = await Auth.confirmSignUp(store.email, verifyCode)
        console.tron.log("confirmSignUp response", response)
        if (response === "SUCCESS") {
          store.onSignIn()
        }
      } catch (error) {
        console.tron.log("confirmSignUp Error:", error)
        store.setProp("error", error.message)
      } finally {
        store.setProp("isLoading", false)
      }
    },
    onResendConfirmAccount: async () => {
      store.setProp("isLoading", true)
      try {
        console.tron.log("onResendConfirmAccount Submit")
        const response = await Auth.resendSignUp(store.email)
        console.tron.log("onResendConfirmAccount Response", response)
      } catch (error) {
        console.tron.log("onResendConfirmAccount Error", error)
      } finally {
        store.setProp("isLoading", false)
      }
    },
    onVerifyPhoneCode: async (verificationCode) => {
      store.setProp("isLoading", true)
      try {
        console.tron.log("onVerifyPhoneCode")
        const response = await Auth.verifyCurrentUserAttributeSubmit(
          "phone_number",
          verificationCode,
        )
        console.tron.log("onVerifyPhoneCode Response", response)
        if (response === "SUCCESS") {
          store.onSignIn()
        }
      } catch (error) {
        console.tron.log("onVerifyPhoneCode Error:", error)
        store.setProp("error", error.message)
        
      } finally {
        store.setProp("isLoading", false)
      }
    },
    onRequestPhoneCode: async () => {
      store.setProp("isLoading", true)
      try {
        console.tron.log("onRequestPhoneCode Submit")
        const response = await Auth.verifyCurrentUserAttribute("phone_number")
        console.tron.log("onRequestPhoneCode Response", response)
      } catch (error) {
        console.tron.log("onRequestPhoneCode Error", error)
      } finally {
        store.setProp("isLoading", false)
      }
    },
  }))
  .preProcessSnapshot((snapshot) => {
    // remove sensitive data from snapshot to avoid secrets
    // being stored in AsyncStorage in plain text if backing up store
    const { authToken, password: authPassword, ...rest } = snapshot // eslint-disable-line @typescript-eslint/no-unused-vars

    // see the following for strategies to consider storing secrets on device
    // https://reactnative.dev/docs/security#storing-sensitive-info

    return rest
  })

export interface AuthenticationStore extends Instance<typeof AuthenticationStoreModel> {}
export interface AuthenticationStoreSnapshot extends SnapshotOut<typeof AuthenticationStoreModel> {}

// @demo remove-file
