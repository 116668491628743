import { Amplify } from "aws-amplify"

import { Platform } from "react-native"

import Config from "../../config"

const poolData = {
  UserPoolId: Config.AWS_COGNITO_USER_POOL_ID,
  ClientId: Config.AWS_COGNITO_CLIENT_ID,
}

console.log("isMobile", Platform.OS)

Amplify.configure({
  Auth: {
    identityPoolId: Config.AWS_COGNITO_IDENTITY_POOL_ID,
    mandatorySignIn: true,
    region: Config.AWS_REGION,
    signUpVerificationMethod: "link", // 'code' | 'link'
    userPoolId: poolData.UserPoolId,
    userPoolWebClientId: poolData.ClientId,
    cookieStorage:
      Platform.OS === "web"
        ? {
            domain: window.location.hostname,
            path: "/",
            expires: 1/24,
            secure: process.env.NODE_ENV === "production",
          }
        : null,

    // OPTIONAL - customized storage object
    // storage: MyStorage,
    authenticationFlowType: "USER_SRP_AUTH",

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: "myCustomValue" },
  },
})
