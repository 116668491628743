export interface ConfigBaseProps {
  AWS_COGNITO_USER_POOL_ID: string
  AWS_COGNITO_CLIENT_ID: string
  AWS_COGNITO_IDENTITY_POOL_ID: string
  AWS_REGION: string
  persistNavigation: "always" | "dev" | "prod" | "never"
  catchErrors: "always" | "dev" | "prod" | "never"
  exitRoutes: string[]
}

export type PersistNavigationConfig = ConfigBaseProps["persistNavigation"]

const BaseConfig: ConfigBaseProps = {
  AWS_COGNITO_USER_POOL_ID: "us-east-1_v0V95D2C8",
  AWS_COGNITO_CLIENT_ID: "4euq703l1c3hvibor5ocjei9o1",
  AWS_COGNITO_IDENTITY_POOL_ID: "us-east-1:15ec474e-4e9c-4ffd-a90d-b4fb5c66e3e7",
  AWS_REGION: "us-east-1",
  // This feature is particularly useful in development mode, but
  // can be used in production as well if you prefer.
  persistNavigation: "never",

  /**
   * Only enable if we're catching errors in the right environment
   */
  catchErrors: "always",

  /**
   * This is a list of all the route names that will exit the app if the back button
   * is pressed while in that screen. Only affects Android.
   */
  exitRoutes: ["Welcome"],
}

export default BaseConfig
