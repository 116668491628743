import { ApolloError, gql, QueryResult, useQuery } from "@apollo/client"

export const GET_USERS = gql`
  query users {
    users
  }
`

export interface ClientUpdate {
  data?: {
    users: string
  }
  error: ApolloError
}

export default function useUsers(): QueryResult {
  return useQuery(GET_USERS)
}
