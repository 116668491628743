/* eslint-disable camelcase */
import { observer } from "mobx-react-lite"
import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { TextInput, TextStyle, ViewStyle } from "react-native"
import { Button, Icon, Screen, Text, TextField, TextFieldAccessoryProps } from "../components"
import { useStores } from "../models"
import { AppStackScreenProps } from "../navigators"
import { colors, spacing } from "../theme"

interface LoginScreenProps extends AppStackScreenProps<"Login"> {}

export const LoginScreen: FC<LoginScreenProps> = observer(function LoginScreen(_props) {
  const authPasswordInput = useRef<TextInput>()
  const authNameInput = useRef<TextInput>()
  const authFamilyNameInput = useRef<TextInput>()
  const authPhoneInput = useRef<TextInput>()
  // const authEmailVerificationInput = useRef<TextInput>()

  const [isAuthPasswordHidden, setIsAuthPasswordHidden] = useState(true)
  const [attemptsCount, setAttemptsCount] = useState(0)

  const {
    authenticationStore: {
      email,
      name,
      family_name,
      password,
      phone_number,
      verifyCode,
      validationErrors,
      error,
      isEmailInvalidated,
      isLoading,
      isLogging,
      isPhoneInvalidated,
      isRegistering,
      onConfirmAccount,
      onGetCurrentSession,
      onLogin,
      onRegister,
      onRequestPhoneCode,
      onReset,
      onSignIn,
      onSignUp,
      onVerifyPhoneCode,
      setAuthEmail,
      setAuthFamilyName,
      setAuthName,
      setAuthPassword,
      setAuthPhone,
      setVerifyCode,
    },
  } = useStores()

  useEffect(() => {
    setAuthEmail("")
    setAuthPassword("")
    onReset()
    onGetCurrentSession()
    return () => {
      setAuthPassword("")
      setAuthEmail("")
    }
  }, [])

  function login() {
    setAttemptsCount(attemptsCount + 1)

    if (Object.values(validationErrors).some((v) => !!v)) return

    if (isRegistering) {
      onSignUp()
    } else {
      onSignIn()
    }
    // Make a request to your server to get an authentication token.
    // If successful, reset the fields and set the token.
    // setIsSubmitted(false)
    // setAuthPassword("")
    // setAuthEmail("")

    // // We'll mock this with a fake token.
    // setAuthToken(String(Date.now()))
  }

  const PasswordRightAccessory = useMemo(
    () =>
      function PasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Icon
            icon={isAuthPasswordHidden ? "view" : "hidden"}
            color={colors.palette.neutral800}
            containerStyle={props.style}
            onPress={() => setIsAuthPasswordHidden(!isAuthPasswordHidden)}
          />
        )
      },
    [isAuthPasswordHidden],
  )

  const toogleButton = () => {
    !isRegistering ? onRegister() : onLogin()
  }

  return (
    <Screen
      preset="auto"
      contentContainerStyle={$screenContentContainer}
      safeAreaEdges={["top", "bottom"]}
    >
      <Text testID="login-heading" tx="loginScreen.signIn" preset="heading" style={$signIn} />
      <Text tx="loginScreen.enterDetails" preset="subheading" style={$enterDetails} />
      {attemptsCount > 2 && <Text tx="loginScreen.hint" size="sm" weight="light" style={$hint} />}
      {(isLogging || isRegistering) && (
        <>
          <TextField
            value={email}
            onChangeText={setAuthEmail}
            containerStyle={$textField}
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect={false}
            keyboardType="email-address"
            labelTx="loginScreen.emailFieldLabel"
            placeholderTx="loginScreen.emailFieldPlaceholder"
            // helper={errors?.authEmail}
            // status={errors?.authEmail ? "error" : undefined}
            onSubmitEditing={() => authPasswordInput.current?.focus()}
          />

          <TextField
            ref={authPasswordInput}
            value={password}
            onChangeText={setAuthPassword}
            containerStyle={$textField}
            autoCapitalize="none"
            autoComplete="password"
            autoCorrect={false}
            secureTextEntry={isAuthPasswordHidden}
            labelTx="loginScreen.passwordFieldLabel"
            placeholderTx="loginScreen.passwordFieldPlaceholder"
            // helper={errors?.authPassword}
            // status={errors?.authPassword ? "error" : undefined}
            // onSubmitEditing={login}
            RightAccessory={PasswordRightAccessory}
          />
          {isRegistering && (
            <>
              <TextField
                ref={authNameInput}
                value={name}
                onChangeText={setAuthName}
                containerStyle={$textField}
                autoCapitalize="words"
                autoComplete="name"
                autoCorrect={false}
                labelTx="loginScreen.nameFieldLabel"
                placeholderTx="loginScreen.nameFieldPlaceholder"
                // helper={errors?.authPassword}
                // status={errors?.authPassword ? "error" : undefined}
                // onSubmitEditing={login}
              />
              <TextField
                ref={authFamilyNameInput}
                value={family_name}
                onChangeText={setAuthFamilyName}
                containerStyle={$textField}
                autoCapitalize="words"
                autoComplete="name-family"
                autoCorrect={false}
                labelTx="loginScreen.familyNameFieldLabel"
                placeholderTx="loginScreen.familyNameFieldPlaceholder"
                // helper={errors?.authPassword}
                // status={errors?.authPassword ? "error" : undefined}
                // onSubmitEditing={login}
              />
              <TextField
                ref={authPhoneInput}
                value={phone_number}
                onChangeText={setAuthPhone}
                containerStyle={$textField}
                autoComplete="tel"
                autoCorrect={false}
                labelTx="loginScreen.phoneNumberFieldLabel"
                placeholderTx="loginScreen.phoneNumberFieldPlaceholder"
                // helper={errors?.authPassword}
                // status={errors?.authPassword ? "error" : undefined}
                // onSubmitEditing={login}
              />
            </>
          )}
          <Text>{JSON.stringify(error)}</Text>
          <Button
            testID="login-button"
            tx={isLoading ? "common.loading" : "loginScreen.tapToSignIn"}
            style={$tapButton}
            preset="reversed"
            onPress={login}
          />
          <Button
            testID="toogle-button"
            tx="loginScreen.tapToSignUp"
            style={$tapButton}
            preset="reversed"
            onPress={toogleButton}
          />
        </>
      )}
      {isEmailInvalidated && (
        <>
          <TextField
            // ref={authEmailVerificationInput}
            value={verifyCode}
            onChangeText={setVerifyCode}
            containerStyle={$textField}
            autoComplete="off"
            autoCorrect={false}
            labelTx="loginScreen.emailVerifyFieldLabel"
            placeholderTx="loginScreen.emailVerifyFieldPlaceHolder"
            // helper={errors?.authPassword}
            // status={errors?.authPassword ? "error" : undefined}
            // onSubmitEditing={login}
          />
          <Button
            testID="validate-mail-button"
            tx="loginScreen.tapToValidateMail"
            style={$tapButton}
            preset="reversed"
            onPress={() => onConfirmAccount(verifyCode)}
          />
        </>
      )}
      {isPhoneInvalidated && (
        <>
          <TextField
            // ref={authEmailVerificationInput}
            value={verifyCode}
            onChangeText={setVerifyCode}
            containerStyle={$textField}
            autoComplete="off"
            autoCorrect={false}
            labelTx="loginScreen.phoneNumberVerifyFieldLabel"
            placeholderTx="loginScreen.phoneNumberVerifyFieldPlaceHolder"
            // helper={errors?.authPassword}
            // status={errors?.authPassword ? "error" : undefined}
            // onSubmitEditing={login}
          />
          <Text onPress={onRequestPhoneCode}>Resend code</Text>
          <Button
            testID="validate-mail-button"
            tx="loginScreen.tapToValidatePhone"
            style={$tapButton}
            preset="reversed"
            onPress={() => onVerifyPhoneCode(verifyCode)}
          />
        </>
      )}
    </Screen>
  )
})

const $screenContentContainer: ViewStyle = {
  paddingVertical: spacing.huge,
  paddingHorizontal: spacing.large,
}

const $signIn: TextStyle = {
  marginBottom: spacing.small,
}

const $enterDetails: TextStyle = {
  marginBottom: spacing.large,
}

const $hint: TextStyle = {
  color: colors.tint,
  marginBottom: spacing.medium,
}

const $textField: ViewStyle = {
  marginBottom: spacing.large,
}

const $tapButton: ViewStyle = {
  marginTop: spacing.extraSmall,
}

// @demo remove-file
